// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
// ...

function useFavicon(length) {
  const [number, set] = useState(1);

  let timer;
  useEffect(() => {
    const changeIcon = () => {
      set(prev => {
        return prev === length ? 1 : prev + 1
      })
    }
    timer = setInterval(changeIcon, 1000)
    return () => {
      clearInterval(timer);
    }
  }, [])

  return `/favicon_0${number}.png`
}

function Meta({ description, lang, meta, title, shareImage }) {

  const { defaultTitle, defaultDescription, defaultShareImage } = useSettings();

  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaImage = `https:${shareImage}` || defaultShareImage?.file?.url || "";

  const favicon = useFavicon(4);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@therepublicofparkroyal`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.object
};

export default Meta;

import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle: defaultMetaTitle
          defaultDescription: defaultMetaDescription
          defaultShareImage {
						file {
							url
						}
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}

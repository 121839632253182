// Dependencies
import React from "react"
// Components
import Header from "../components/Header"
import Meta from "../components/Meta"
// CSS
import "../css/styles.scss"

const Layout = ({ title, children }) => (
  <>
    <Meta title={title} />
    {/* <Header /> */}
    <main>{children}</main>
  </>
)

export default Layout
